
import { useStore } from "vuex"
import { useRouter } from 'vue-router'
import { defineComponent, ref } from 'vue'

import AuthService from '@/core/services/AuthService'
import { Actions } from "@/store/enums/store.enums"

import { ElMessage } from 'element-plus'
import { validationRules } from '@/core/helpers/validation/validationRules'


export default defineComponent({
  name: 'login-page',
  setup() {
    const store = useStore()
    const router = useRouter()

    interface IntAuthData {
      login: string | null
      password: string | null
    }

    const isFormLoading = ref<boolean>(false)

    const refLoginForm = ref(null)

    const authData = ref<IntAuthData>({
      login: null,
      password: null,
    })

    const rules = {
      login: [
        {
          required: true,
          trigger: 'blur',
          message: validationRules.common.messages.required,
        },
      ],
      password: [
        {
          required: true,
          trigger: 'blur',
          message: validationRules.common.messages.required,
        },
      ],
    }

    const validateTheForm = (): boolean => {
      // return loginForm.value.validate(valid => valid)
      let result = false
      refLoginForm.value.validate(valid => {
        if (valid) {
          result = true
        } else {
          result = false
        }
      })
      return result
    }

    const submitForm = async () => {
      if (validateTheForm()) {
        isFormLoading.value = true

        const params = {
          email: authData.value.login,
          password: authData.value.password,
        }

        const loginResult = await AuthService.logIn(params)

        if (loginResult) {
          // console.log('> TRUE')
          const adminData = await store.dispatch(Actions.REQUEST_ADMIN_DATA)
          // console.log('> adminData', adminData)
          router.push({ name: 'news' })
          ElMessage.success('Вы вошли на сайт')
        }

        isFormLoading.value = false
      }
    }

    return {
      rules,
      authData,
      refLoginForm,
      isFormLoading,
      submitForm,
      validateTheForm,
    }
  },
})
